<template>
  <div class="v1-layout">
    <notifications position="top right" />

    <div
      :class="['main-container', {
        'nomenu': !user,
        'topmenu': !withoutHeader,
      }]"
    >
      <div
        ref="wrapper"
        class="content-wrapper"
      >
        <page-bottom-banner />
        <common-modals />

        <app-header v-if="!withoutHeader" />
        <app-header-modal v-if="currentModal" />

        <div
          class="main-content"
          :class="[{ 'topmenu': !withoutHeader }, { 'withfooter': !withoutFooter }]"
        >
          <nuxt />
          <the-footer v-if="!withoutFooter && 0" />
          <app-footer v-if="!withoutFooter" />
        </div>
      </div>

      <register-form
        v-if="!user && registerFormShow "
        :show-form="regopt.showForm"
        @close="closeRegisterForm"
        @login="login"
        @register="register"
        @submitRemember="submitRemember"
      />
      <!-- eslint-disable max-len vue/max-len -->
      <popup-message
        v-if="registerDone"
        message="Мы отправили ссылку для подтверждения регистрации вам на почту. Пожалуйста, перейдите по этой ссылке, чтобы подтвердить свой e-mail."
        @close="registerDone = false"
      />
      <!-- eslint-enable -->
      <popup-message
        v-if="rememberDone"
        message="Мы отправили новый пароль вам на почту."
        @close="rememberDone = false;loginForm()"
      />
      <div
        v-if="withoutHeader"
        class="footer-sticky"
      >
        <button
          class="btn mobile-reg-button"
          @click="registerForm"
        >
          Бесплатная консультация
        </button>
      </div>
      <a
        v-if="!(withoutHeader || withoutFooter)"
        :href="`tel:${phone}`"
        class="sticky-call-phone"
      >
        <img
          :src="require('~/static/icon/phone-call.svg')"
          alt="call"
        >
      </a>
      <modal-container />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import redirector from '@/service/redirector';
import { addAdmitadScript, addDeduplicationScript } from '~/utils/addAdmitadScript';

import RegisterForm from '@/components/auth/registerForm.vue';
import PopupMessage from '@/components/other/popupMessage.vue';
import TheFooter from '@/components/other/theFooter.vue';
import ModalContainer from '@/components/popups/ModalContainer.vue';
import AppFooter from '@/components/v2/common/AppFooter.vue';
import PageBottomBanner from '@/components/school/PageBottomBanner.vue';
import CommonModals from '@/components/v2/modals/CommonModals.vue';
import AppHeader from '~/components/v2/common/AppHeader/AppHeader.vue';
import AppHeaderModal from '~/components/v2/common/AppHeader/AppHeaderModal.vue';

/**
 * TODO: удалить старый код авторизации/регистрации
 */
export default {
  name: 'LayoutDefault',
  components: {
    AppHeaderModal,
    AppHeader,
    CommonModals,
    PageBottomBanner,
    ModalContainer,
    PopupMessage,
    RegisterForm,
    TheFooter,
    AppFooter,
  },
  props: {
    withoutHeader: {
      type: Boolean,
      default: false,
    },
    withoutFooter: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    registerFormShow: false,
    registerDone: false,
    rememberDone: false,
    // Флаги формы регистрации
    regopt: {},
  }),
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('appHeaderModals', ['currentModal']),
    phone() {
      return process.env.phone;
    },
  },

  mounted() {
    addDeduplicationScript();
    addAdmitadScript();

    window.addEventListener('resize', this.setWindowSize);
    this.setWindowSize({ target: window });

    this.$root.$on('RegisterForm', this.registerForm);
    this.$root.$on('registerDone', this.register);
    this.$root.$on('showLogin', this.loginForm);
    this.$store.dispatch('storeRef');
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setWindowSize);
  },
  destroyed() {
    this.$root.$off('RegisterForm', this.registerForm);
    this.$root.$off('registerDone', this.register);
    this.$root.$off('showLogin', this.loginForm);
  },
  methods: {
    ...mapActions({
      setWindowSize: 'setWindowSize',
    }),
    closeRegisterForm() {
      const { afterClose } = this.regopt;
      if (afterClose) {
        if (typeof afterClose === 'function') {
          return afterClose.apply([true]);
        }
        if (/^http/.test(afterClose)) {
          window.location = afterClose;
        } else {
          this.$router.push(afterClose);
        }
      }
      this.registerFormShow = false;
      return true;
    },
    login(user) {
      this.$store.dispatch('login', user);
      this.registerFormShow = false;
      const { afterRegister } = this.regopt;
      if (afterRegister) {
        if (typeof afterRegister === 'function') {
          return afterRegister.apply([true]);
        }
        if (/^http/.test(afterRegister)) {
          window.location = afterRegister;
        } else {
          this.$router.push(afterRegister);
        }
      }
      if (this.$store.getters.loginEmail) {
        return redirector.toSchool();
      }
      return redirector.toCore();
    },
    register(user) {
      const { email, noRedirectToQuiz } = user;
      this.registerFormShow = false;
      // this.registerDone = true

      this.$store.commit('loginEmail', email);
      try {
        this.$gtm.push({ event: 'SignUpForm' });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Error send source');
      }
      if (noRedirectToQuiz) {
        this.$store.dispatch('login', user);
        this.registerFormShow = false;
        return;
      }
      window.location = redirector.linkToCore();
    },
    submitRemember({ email }) {
      this.registerFormShow = false;
      this.$store.commit('loginEmail', email);
      this.rememberDone = true;
    },
    registerForm(opt = {}) {
      // eslint-disable-next-line no-param-reassign
      opt.message = opt.message || 'Регистрация';
      this.regopt = { ...opt, showForm: 'register' };
      if (!this.user) {
        this.registerFormShow = true;
      } else {
        if (opt.afterRegister) {
          if (typeof opt.afterRegister === 'function') {
            return opt.afterRegister.apply();
          }
          if (/^http/.test(opt.afterRegister)) {
            window.location = opt.afterRegister;
          } else {
            this.$router.push(opt.afterRegister);
          }
        }
        return redirector.toToPayment();
      }
      return true;
    },
    loginForm() {
      if (!this.user) {
        this.regopt = { showForm: 'login' };
        this.registerFormShow = true;
      }
    },
    signUp() {
      this.$root.$emit('signUp');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .page-banner ~ .top-menu {
  @include media-up(tablet) {
    top: 100px !important;
  }
}

::v-deep .page-bottom-banner {
  z-index: 99;
}

::v-deep .page-bottom-banner ~ .main-content {
  padding-bottom: 100px;

  @include media-down(tablet) {
    padding-bottom: 80px;
  }
}

.content-wrapper {
  background-color: #ECEDF0;
}

.content-wrapper.opened {
  opacity: 0.5;
}

.main-content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  overflow-x: hidden;

  & > div {
    flex-grow: 1;
  }
}
.main-content.topmenu {
  padding-top: calc(var(--scale) * 90);
}
.main-content.withfooter {
  ::v-deep .footer {
    margin-top: 100px;

    @include media-down(tablet) {
      margin-top: 70px;
    }
  }
}

.v1-layout {
  &:has(.dropdown-menu__mobile) {
    @include media-down($size-tablet) {
      .page-bottom-banner {
        display: none;
      }

      ::v-deep .header {
        top: 0 !important;
      }
    }
  }

  ::v-deep .page-bottom-banner {
    & ~ .header__wrapper header,
    & ~ .header {
      @include media-down($size-tablet) {
        top: calc(var(--scale) * 50);

        .header__inner {
          top: 0;
        }
      }
    }

    & ~ .main-content.topmenu {
      @include media-down($size-tablet) {
        padding-top: calc(var(--scale) * 60);
      }
    }

    & ~ .footer {
      padding-bottom: calc(var(--scale) * 100);
    }
  }

  .footer {
    @include media-down($size-tablet) {
      padding-bottom: calc(var(--scale) * 80) !important;
    }
  }
}

.sticky-call-phone {
  display: none;
  position: fixed;
  right: 2%;
  bottom: 1%;
  z-index: 80;

  @include media-down(mobile) {
    display: block;
  }
}

.footer-sticky {
  display: none;
  background-color: rgba(255, 255, 255, 0.5);
  padding-top: 1em;
  padding-bottom: 1em;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 97;

  @include media-down(mobile) {
    display: block;

    .btn {
      width: 90%;
      height: 50px;
      margin: 0 auto;
      align-items: center;
      display: flex;
      font-size: 20px;
      padding: 1.5em 0;
    }
  }
}

 ::v-deep .notification-title {
   margin-bottom: 0.75em;
 }

</style>
